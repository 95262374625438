import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'
import router from './router'

const app = createApp(App);

// 将 Axios 实例挂载到 Vue 原型上
app.config.globalProperties.$axios = axios;

app.use(router).mount('#app');
