<template>
    <li>
        <div @click="toggle">
            <!-- 使用 v-if 检查 node.icon 是否存在 -->
            <template v-if="node.icon">
                <!-- 如果 node.icon 是 SVG 文件路径 -->
                <img v-if="isSvgIcon(node.icon)" :src="node.icon" class="icon">
                <!-- 如果 node.icon 是内联 SVG 代码 -->
                <svg v-else class="icon" v-html="node.icon"></svg>
            </template>
            <span class="label">{{ node.label }}</span>
        </div>
        <ul v-if="isOpen && node.children && node.children.length">
            <tree-node
                v-for="child in node.children"
                :key="child.id"
                :node="child"
                @node-click="$emit('node-click', $event)"
            ></tree-node>
        </ul>
    </li>
</template>

<script>
export default {
    name: 'TreeNode',
    props: {
        node: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            isOpen: false // 默认闭合
        };
    },
    methods: {
        toggle() {
            this.isOpen = !this.isOpen;
            this.$emit('node-click', this.node);
        },
        isSvgIcon(icon) {
            // 简单判断是否为 SVG 文件路径
            return icon.endsWith('.svg');
        }
    }
};
</script>

<style scoped>
li {
    cursor: pointer;
    padding: 3px;
}
div {
    display: flex;
    align-items: center;
}
.icon {
    width: 16px;
    height: 16px;
    margin-right: 5px;
    fill: currentColor; /* 使用 currentColor 来继承父元素的颜色 */
}
.label {
    color: white; /* 设置文本颜色为白色 */
}
ul {
    padding-left: 20px;
    list-style-type: none;
}
</style>
