// src/api/apiService.js
import axios from 'axios';
import apiConfig from '@/config/api';

class http {
    constructor() {
        this.instance = axios.create({
            baseURL: apiConfig.baseURL,
            timeout: 3000,
            headers: apiConfig.headers
        });
    }

    // GET 请求
    async get(url, params = {}) {
        try {
            const response = await this.instance.get(url, { params });
            return response.data;
        } catch (error) {
            console.error('GET request error:', error);
            throw error;
        }
    }

    // POST 请求
    async post(url, data = {}) {
        try {
            const response = await this.instance.post(url, data);
            return response.data;
        } catch (error) {
            console.error('POST request error:', error);
            throw error;
        }
    }

    // PUT 请求
    async put(url, data = {}) {
        try {
            const response = await this.instance.put(url, data);
            return response.data;
        } catch (error) {
            console.error('PUT request error:', error);
            throw error;
        }
    }

    // DELETE 请求
    async delete(url, params = {}) {
        try {
            const response = await this.instance.delete(url, { params });
            return response.data;
        } catch (error) {
            console.error('DELETE request error:', error);
            throw error;
        }
    }
}

export default new http();
