<template>
    <div class="container">
        <div class="tree-container" :class="{ hidden: isTreeHidden }">
            <ul v-show="!isTreeHidden">
                <tree-node
                    v-for="node in nodes"
                    :key="node.id"
                    :node="node"
                    @node-click="handleNodeClick"
                ></tree-node>
            </ul>
        </div>
        <button class="toggle-button" @click="toggleTree"></button>
        <div class="content-container" :class="{ full: isTreeHidden }">
            <iframe v-if="iframeSrc" :src="iframeSrc" ref="contentIframe" frameborder="0"></iframe>
        </div>
    </div>
</template>

<script>
import TreeNode from '@/components/TreeNode.vue';
import http from "@/utils/http";

export default {
    name: 'TreeView',
    components: {
        TreeNode
    },
    data() {
        return {
            nodes: [],
            iframeSrc: '/doc_index.html',
            isTreeHidden: false // 控制树形组件显示/隐藏的状态
        };
    },
    mounted() {
        this.test(); // 页面加载时调用 init 方法
        this.setTreeVisibility();
        window.addEventListener('resize', this.setTreeVisibility); // 监听窗口大小变化
    },
    beforeUnmount() {
        window.removeEventListener('resize', this.setTreeVisibility); // 移除监听器
    },
    methods: {
        async test() {
            let url = "https://docs.recallg.com/tree.php";
            try {
                this.nodes = await http.post(url);
            } catch (err) {
                console.log(err)
            }
        },
        handleNodeClick(node) {
            if (node.url) {
                this.iframeSrc = node.url;
                this.isTreeHidden = true; // 隐藏树形组件
            }
        },
        toggleTree() {
            this.isTreeHidden = !this.isTreeHidden;
        },
        setTreeVisibility() {
            const width = window.innerWidth;
            if (width <= 768) {
                this.isTreeHidden = true; // 移动端隐藏树形组件
            } else {
                this.isTreeHidden = false; // PC 和 iPad 显示树形组件
            }
        }
    }
};
</script>

<style scoped>
.container {
    display: flex;
    height: 100vh; /* 使容器填满视口高度 */
    flex-direction: row; /* 默认水平布局 */
    position: relative; /* 使按钮的绝对定位相对于容器 */
    overflow: hidden; /* 隐藏水平滚动条 */
}

.tree-container {
    flex: 0 0 300px; /* 固定宽度 */
    max-width: 300px; /* 根据需要调整 */
    overflow-y: auto; /* 如果树形组件内容超出容器高度，显示滚动条 */
    transition: max-width 0.3s ease, transform 0.3s ease; /* 添加过渡效果 */
}

.tree-container.hidden {
    max-width: 0; /* 隐藏树形组件 */
    transform: translateX(-100%); /* 隐藏树形组件 */
}

.toggle-button {
    position: absolute;
    top: 50%; /* 垂直居中 */
    left: 278px; /* 紧贴在 tree-container 的右边 */
    transform: translateY(-50%);
    z-index: 10;
    width: 40px; /* 根据需要调整 */
    height: 40px; /* 根据需要调整 */
    background-color: transparent; /* 背景透明 */
    border: none;
    cursor: pointer;
    transition: left 0.3s ease; /* 添加过渡效果 */
}

.toggle-button::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 20px 14px 20px 0; /* 调整三角形大小 */
    border-color: transparent #CCCC33 transparent transparent;
    clip-path: polygon(100% 0, 0 50%, 100% 100%); /* 创建一个向右的三角形 */
    transition: transform 0.3s ease;
}

.tree-container.hidden + .toggle-button {
    left: 0; /* 当 tree-container 隐藏时，按钮移到左边 */
}

.tree-container.hidden + .toggle-button::before {
    transform: rotate(180deg); /* 当 tree-container 隐藏时，三角形旋转180度 */
}

.toggle-button::before {
    border-radius: 5px; /* 添加圆角效果 */
}

.content-container {
    flex: 1;
    overflow-y: auto; /* 使内容区域可以独立滚动 */
    transition: flex 0.3s ease; /* 添加过渡效果 */
    display: flex;
    flex-direction: column;
}

.content-container.full {
    flex: 1 0 100%; /* 全屏显示内容区域 */
}

iframe {
    width: 100%; /* 设置 iframe 的宽度为 100% */
    height: 100%;
    border: none;
    overflow-x: hidden; /* 隐藏 iframe 内的水平滚动条 */
}

ul {
    list-style-type: none;
    padding-left: 32px;
    margin-top: 4rem;
    line-height: 24px;
}

/* 响应式设计 */
@media (max-width: 768px) {
    .container {
        flex-direction: row; /* 保持水平布局 */
    }

    .tree-container {
        flex: 0 0 100%; /* 占满宽度 */
        max-width: 100%; /* 占满宽度 */
        margin-top: 0;
        border-right: 1px solid #ccc; /* 保持右边框 */
    }

    .content-container {
        flex: 1;
        padding: 10px;
    }

    .toggle-button {
        top: 50%;
        left: 94%; /* 调整按钮位置 */
        transform: translateY(-50%);
    }

    .tree-container.hidden + .toggle-button {
        left: 0;
    }
}
</style>
