<template>
<!--    <nav>-->
<!--        <router-link to="/">Home</router-link>-->
<!--        |-->
<!--        <router-link to="/tree">Tree</router-link>-->
<!--        |-->
<!--        <router-link to="/about">About</router-link>-->
<!--    </nav>-->
    <router-view/>
</template>

<style>
#app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
    background-color: #0f172a;
}

/*
nav {
    padding: 30px;
}

nav a {
    font-weight: bold;
    color: #2c3e50;
}

nav a.router-link-exact-active {
    color: #42b983;
}*/
</style>
<script setup lang="ts">
</script>
